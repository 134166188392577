<template>
	<div class="wrap">
		<div class="rich"></div>
		<div class="enclosure flex-wrap">附件：<span>政策规定</span></div>
		<div class="article flex-direction-column">
			<span>上一篇：江苏田协会召开全国路跑赛事</span>
			<span>下一篇：报名开启！2022江苏半程马拉松定档及相关规定</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'podetail',
	data() {
		return {}
	},

	mounted() {
		let index = this.$route.params.index
		this.$route.meta.asideNavMenuIndex = index
		if (index == 0) {
			this.$route.meta.pathName = '最新政策详情'
		} else if (index == 1) {
			this.$route.meta.pathName = '政策通知详情'
		}
	},

	methods: {}
}
</script>

<style lang="less" scoped>
.wrap {
	padding: 30px 44px 40px 44px;
	background: #fff;

	.enclosure {
		font-size: 16px;
		color: #333333;
		padding-bottom: 36px;
		border-bottom: 1px solid #eee;
		cursor: pointer;

		span {
			margin-right: 10px;
			color: #4786e9;
		}
	}

	.article {
		margin-top: 37px;
		cursor: pointer;

		span {
			height: 25px;
			font-size: 14px;
			line-height: 25px;
			color: #333333;
			margin-bottom: 13px;

			&:hover {
				color: #999999;
			}
		}
	}
}
</style>
